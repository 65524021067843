@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

html {
  /* font-size => 10px */
  /* 10px /16px => 0.625 => 62.5% */
  /* Percentage of user's browser font-size setting */
  font-size: 62.5% !important;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
  width: 1rem;
  height: auto;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 1rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bcbcbc;
  border-radius: 1rem
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bcbcbc
}

@media (max-width: 1600px) {
  html {
    /* font-size => 8px */
    /* 9px /16px => .5625 => 56.25% */
    /* Percentage of user's browser font-size setting */
      font-size: 56.25% !important
    /* overflow-x: hidden */
  }
}

@media (max-width: 1200px) {
  html {
    /* font-size => 8px */
    /* 8px /16px => 0.5 => 50% */
    /* Percentage of user's browser font-size setting */
      font-size: 50% !important
    /* overflow-x: hidden */
  }
}

@media (max-width: 800px) {
  html {
    /* font-size => 6px */
    /* 7px /16px => 0.4375 => 43.75% */
    /* Percentage of user's browser font-size setting */
      font-size: 43.75% !important
    /* overflow-x: hidden */
  }
  
  /* width */
  ::-webkit-scrollbar {
    width: .4rem;
    height: auto;
  }
  
  @-moz-document url-prefix(){
    /* width */
    ::-webkit-scrollbar {
      width: .4rem;
      height: auto
    }
  }
}

@media (max-width: 500px) {
  html {
    /* font-size => 6px */
    /* 6px /16px => 0.375 => 37.5% */
    /* Percentage of user's browser font-size setting */
      font-size: 37.5% !important
    /* overflow-x: hidden */
  }
}
