.App {
  width: 100vw;
  margin: auto 0;
}

.container-lg {
  max-width: 1600px;
  margin: 0 auto;
}

.grayscale-img {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.grayscale-img:hover {
  -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
  filter: grayscale(0);
}


.bg-gredient-1 {
  background: linear-gradient(to bottom,#ffffff 0%,#ffffff 67%,#F2F4F4 67%,#F2F4F4 100%)!important;
}

.flex-container {
  display: flex;
  align-items: center;
}

.clip-diagonal {
  clip-path: polygon(0 0, 80% 0, 100% 20%, 100% 100%, 0 100%);
}


.bg-gradient-2 {
  background: linear-gradient(to bottom, #ffffff 0%, #ffffff 33%, #2666CF 33%, #2666CF 66%, #070F29 66%, #070F29 100% ) !important;
}

.industryecomm{
  background-image: url(./static/assets/img/backgrounds/industries-landing-bg.jpg);
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center ;
}

.captcha-container {
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  padding: 5px 50px 0px;
  background-color: #f2f2f2;
  border: 2px solid #000;
  letter-spacing: 3px;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
}

.captcha-text {
  display: inline-block;
  font-family: "Courier New", Courier, monospace;
  transform: rotate(-5deg) skewX(10deg);
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  color: rgb(0, 102, 204);
  position: relative;
  z-index: 2;
}

/* Random noise effect (background lines & dots) */
.captcha-container::before,
.captcha-container::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: repeating-linear-gradient(
          45deg,
          rgba(0, 0, 0, 0.1) 0px,
          rgba(0, 0, 0, 0.1) 3px,
          transparent 3px,
          transparent 6px
  );
  opacity: 0.4;
  z-index: 1;
}

.captcha-container::after {
  background: radial-gradient(
          circle,
          rgba(0, 0, 0, 0.2) 2%,
          transparent 5%
  );
  background-size: 10px 10px;
  opacity: 0.3;
}

/* .industryfin{
  background-image: url(../../../static/assets/img/backgrounds/industries-landing-bg.jpg);
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center ;
}; */


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (max-width: 1600px) {
  .container-lg {
    max-width: 1400px;
    margin: 0 auto;
  }
}

@media (max-width: 1400px) {
  .container-lg {
    max-width: 1200px;
    margin: 0 auto;
  }
}

@media (max-width: 1200px) {
  .container-lg {
    max-width: 992px;
    margin: 0 auto;
  }
}

@media (max-width: 992px) {
  .container-lg {
    max-width: 768px;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .container-lg {
    max-width: 576px;
    margin: 0 auto;
  }
}

@media (max-width: 576px) {
  .container-lg {
    max-width: 420px;
    margin: 0 auto;
  }
}

@media (max-width: 420px) {
  .container-lg {
    max-width: 350px;
    margin: 0 auto;
  }
}
