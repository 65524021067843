.home-landing-sections-bg {
    z-index: 1;
}

.home-landing-sections-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

/* @media (max-width: 620) {
    .home-landing-sections {
        min-height: 98rem
    }
} */ 

/* .home-landing-sections {
    position: relative;
    overflow: hidden;
  }
  
  .home-landing-sections-bg {
    position: absolute;
    inset: 0;
    z-index: -10;
    background-color: black;
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: -10;
  }
   */