.about-us-exp-image {
    background-image: url(../../../static/img/AboutUsExperienceImage.jpg);
    height: 100%;
    width: 60%;
    background-size: contain;
    background-repeat: no-repeat;
    /* background-position: center; */
}


@media (max-width: 1500px) {
    .about-us-exp-image {
        width: 120%; 
    }
}